import React from 'react';

import withMediaTypes from '../../types/withMediaTypes';
import withMedia from '../../hoc/withMedia';

import ProductList from './ProductList';
import { ALL_PRODUCT_TYPES } from '../../data/products';

class ProductView extends React.PureComponent {
  static propTypes = {
    ...withMediaTypes,
  };

  render() {
    return (
      <article className="product-page">
        <ProductList {...this.props} data={ALL_PRODUCT_TYPES} />
      </article>
    );
  }
}

export default withMedia(ProductView);
