import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import ProductsView from '../components/ProductsView';

import '../styles/global.scss';

class ProductsPage extends React.PureComponent {
  render() {
    return (
      <Layout view="products">
        <Seo title="Products" />
        <ProductsView />
      </Layout>
    );
  }
}

export default ProductsPage;
